<template>
    <div class="relative bg-white h-[calc(100vh_-_var(--header-height))] w-screen at1024:shadow-xl at1024:w-[880px]">
        <div v-if="!isLoading" class="relative overflow-y-auto p-8 at1024:p-16 h-full">
            <DialogCloseButton class="absolute top-4 right-4" />
            <header class="mb-4">
                <h2 class="text-2xl at1024:text-3xl font-medium">
                    {{ $t("Order #") }}
                    <span class="font-bold">{{ data.order }}</span>
                </h2>
                <RecordHeader :record="order" />
            </header>
            <div>
                <OrderRecord v-bind="props" />
            </div>
        </div>
        <div v-else-if="errors" class="grow p-8 flex items-center justify-center">
            <RecordsNotFound
                icon="icon-error-outline"
                header="Something went wrong"
                message="Please try again, and feel free to contact support if the problem persists."
            />
        </div>
        <div v-else class="flex items-center justify-center absolute inset-0">
            <LoadingSpinner :large="true" />
        </div>
    </div>
</template>

<script setup>
import {
    DialogCloseButton,
    Divider,
    LoadingSpinner,
    OrderRecord,
    RecordHeader,
    RecordsNotFound,
} from "@/Components";

const props = defineProps([
    "isLoading",
    "order",
    "data",
    "shipmentColumns",
    "lineColumns",
    "invoiceColumns",
    "lineRows",
    "shipmentRows",
    "invoiceRows",
    "errors",
]);
</script>
