export default function useHelpers() {
    const slugify = (text, separator = '-') => {
        return text
            .toLowerCase()
            .replace(/[^\w\s-]/g, "")
            .replace(/[\s_-]+/g, separator)
            .replace(new RegExp(`^${separator}+|${separator}+$`, 'g'), "");
    };

    const pluralize = (count, singular, plural, token = "##") => {
        return count === 1
            ? singular.replace(token, count)
            : plural.replace(token, count);
    };

    const isDesktop = () => {
        return !/Mobi|Android/i.test(navigator.userAgent);
    };

    const downloadResponse = (response, filename = null) => {
        let contentDisposition = response.headers["content-disposition"];
        if (!filename && contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename=(.+)/);
            if (fileNameMatch.length === 2) {
                filename = fileNameMatch[1];
            }
        }

        const objectUrl = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = objectUrl;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(objectUrl);
    };

    const shortNumber = (number, fraction = 0) => {
        let abs_number = Math.abs(number)
        let units = ['', 'K', 'M', 'B', 'T'];
        let i = 0;
        for (i = 0; abs_number >= 1000; i++) {
            abs_number /= 1000;
        }

        return (number < 0 ? '-' : '') + abs_number.toFixed(fraction) + units[i];
    }

    let currency = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const copy = (value) => navigator.clipboard.writeText(value)

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    return {
        slugify,
        pluralize,
        downloadResponse,
        showMeTheMoney: (amount, truncate = false, symbol = true) => {
            // remove commas from string if present, then convert to number
            let result = typeof amount === 'string'
                ? parseFloat(amount.replace(/,/g, ''))
                : amount

            if (isNaN(result)) {
                return amount;
            }
            return (truncate) ?
                "$" + shortNumber(result) :
                (!symbol ? currency.format(result).replace("$","") : currency.format(result));
        },
        cardValue(card) {
            let formatted = {};
            Object.keys(card).forEach((key) => {
                formatted[key] = card[key].raw ?? card.key;
            });
            return formatted;
        },
        copy,
        copyUrl: () => copy(window.location.href),
        shortNumber,
        scrollToTop,
        scrollToId: (id) => document.getElementById(id).scrollIntoView({ behavior: "smooth"}),
        isDesktop
    };
}
